import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  Grid,
  CircularProgress,
  Button,
  InputAdornment,
  Typography,
  Box,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
  Input,
  Select,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import Fade from 'react-reveal/Fade';
import colors from '../../constants/colors';
import { navigate } from 'gatsby';
import axios from 'axios';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';
import Layout from '../../components/layout';
import ImageContainer from '../../components/image-container';
// import CustomLink from '../../components/custom-link/index';
import RegistrationJson from '../../data/registration.json';
import SearchSelectField from '../../components/registration-form/inputField/search-select-field';
import InputField from '../../components/registration-form/inputField/Input-field';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const interestsData = ['Newsletter', 'Knowledge Hub', 'GMIS Announcements', 'Roadshows'];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ContactUsSchema = Yup.object().shape({
  firstName: Yup.string().min(2, 'Too Short !').max(30, 'Too Long!').required('First name is required'),
  lastName: Yup.string().min(2, 'Too Short !').max(30, 'Too Long!').required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  mobile: Yup.string().required('Mobile number is required').matches(phoneRegExp, 'Invalid mobile number'),
  // areaCode: Yup.string().required('Country Code is required'),
  interests: Yup.array().of(Yup.string()).min(1, 'Intersts are required'),
});

const useStyles = makeStyles((theme) =>
  createStyles({
    multiSelection: {
      '& .MuiFormLabel-root': {
        paddingLeft: '8px !important',
      },
    },
    asteriskColor: {
      color: `${colors.text.primary.dark} !important`,
    },
    selectedBG: {
      // backgroundColor:`${colors.primary.dark}${colors.transparency.button}`,
      color: `${colors.primary.dark} !important`,
      paddingLeft: theme.spacing(2),
    },
    textFieldLabelSpacing: {
      '& .MuiFormLabel-root': {
        marginLeft: '10px',
      },
      '&.MuiFormControlLabel-root': {
        marginRight: '0px',
      },
    },
  })
);

const NewsletterPage = (props) => {
  const classes = useStyles();
  async function handleFormikSubmit(values, actions) {
    try {
      const response = await axios.post(process.env.GATSBY_API_URL + 'newsletter', values);
      // const responsePardot = await axios.post(
      //   'https://cors-anywhere.herokuapp.com/https://go.pardot.com/l/917773/2021-03-09/mzp',
      //   values
      // );
      if (response.status === 200) {
        actions.setSubmitting(false);
        // console.log("200")
        navigate('/newsletter/thankyou?pi_list_email=' + values.email);
      }
    } catch (err) {
      console.log(err);
    }
    // navigate('/newsletter/thankyou');
  }
  return (
    <Layout>
      <SectionContainerLayoutWithFilter title='NEWSLETTER SUBSCRIPTION'>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                areaCode: '',
                country: {},
                mobile: '',
                interests: [],
              }}
              validationSchema={ContactUsSchema}
              onSubmit={handleFormikSubmit}>
              {({ errors, touched, isSubmitting, values, handleChange, handleBlur }) => {
                return (
                  <Fade bottom>
                    <Form id='subscription' autoComplete='off'>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box width={1} mb={3}>
                            <TextField
                              className={classes.textFieldLabelSpacing}
                              name='firstName'
                              label='First Name'
                              type='text'
                              value={values.firstName}
                              error={errors.firstName && touched.firstName}
                              helperText={touched.firstName && errors.firstName ? errors.firstName : ''}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='start'>
                                    <Typography variant='body1'>*</Typography>
                                  </InputAdornment>
                                ),
                              }}
                              fullWidth
                            />
                          </Box>
                          <Box width={1} mb={4}>
                            <TextField
                              className={classes.textFieldLabelSpacing}
                              name='lastName'
                              label='Last Name'
                              type='text'
                              value={values.lastName}
                              error={errors.lastName && touched.lastName}
                              helperText={touched.lastName && errors.lastName ? errors.lastName : ''}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='start'>
                                    <Typography variant='body1'>*</Typography>
                                  </InputAdornment>
                                ),
                              }}
                              fullWidth
                            />
                          </Box>
                          <Box width={1} mb={4}>
                            <TextField
                              className={classes.textFieldLabelSpacing}
                              type='text'
                              name='email'
                              label='Email'
                              error={errors.email && touched.email}
                              value={values.email}
                              helperText={touched.email && errors.email ? errors.email : ''}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='start'>
                                    <Typography variant='body1'>*</Typography>
                                  </InputAdornment>
                                ),
                              }}
                              fullWidth
                            />
                          </Box>
                          <Box width={1} mb={4}>
                            <SearchSelectField
                              name='country'
                              label='Country of Residence'
                              data={RegistrationJson.country}
                              onBlur={handleBlur}
                              value={values.country}
                            />
                          </Box>

                          <Box width={1} mb={4}>
                            <Grid container spacing={2}>
                              <Grid item xs={5} sm={4}>
                                {/* <TextField
                                  className={classes.textFieldLabelSpacing}
                                  type='text'
                                  name='areaCode'
                                  label='Country Code'
                                  error={errors.areaCode && touched.areaCode}
                                  value={values.areaCode}
                                  helperText={touched.areaCode && errors.areaCode ? errors.areaCode : ''}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position='start'>
                                        <Typography variant='body1'>*</Typography>
                                      </InputAdornment>
                                    ),
                                  }}
                                  fullWidth
                                /> */}
                                <Box mt={2}>
                                  <InputField
                                    value={values.country ? values.country.dialCode : ''}
                                    disabled
                                    label=''
                                    name='areaCode'
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={7} sm={8}>
                                <TextField
                                  className={classes.textFieldLabelSpacing}
                                  type='text'
                                  name='mobile'
                                  label='Mobile Number'
                                  error={errors.mobile && touched.mobile}
                                  value={values.mobile}
                                  helperText={touched.mobile && errors.mobile ? errors.mobile : ''}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position='start'>
                                        <Typography variant='body1'>*</Typography>
                                      </InputAdornment>
                                    ),
                                  }}
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                          </Box>
                          <Box width={1} mb={4}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Box mb={2}>
                                  <Typography variant='h6'>Your Interest</Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={12} className={classes.multiSelection}>
                                {/* <Typography variant="h6">Your Interest</Typography> */}
                                <Box clone width={1}>
                                  <FormControl error={errors.interests && touched.interests ? true : false}>
                                    <InputLabel id='interests'>Your Interests</InputLabel>
                                    <Select
                                      className={classes.selectedBG}
                                      labelId='interests'
                                      value={values.interests}
                                      name='interests'
                                      endAdornment={
                                        <InputAdornment position='start'>
                                          <Typography className={classes.asteriskColor} variant='body1'>
                                            *
                                          </Typography>
                                        </InputAdornment>
                                      }
                                      multiple
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      input={<Input id='select-multiple-interests' />}
                                      renderValue={(selected) => (
                                        <div>
                                          {selected.map((item, index) => {
                                            return <Typography key={index}>{item}</Typography>;
                                          })}
                                        </div>
                                      )}
                                      MenuProps={MenuProps}>
                                      {interestsData.map((item, index) => {
                                        return (
                                          <MenuItem key={index} value={item}>
                                            {item}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                    <FormHelperText>
                                      {errors.interests && touched.interests ? errors.interests : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <Box mt={1}>
                            <Button disabled={isSubmitting} fullWidth type='submit' variant='contained' color='primary'>
                              Subscribe
                            </Button>
                            {isSubmitting && <CircularProgress size={24} />}
                          </Box>
                        </Grid>
                      </Grid>
                    </Form>
                  </Fade>
                );
              }}
            </Formik>
            {/* )} */}
          </Grid>
          <Grid item xs={12} md={6}>
            <Fade bottom>
              <Box display={{ xs: 'none', md: 'block' }}>
                <ImageContainer filename='newsletter-new' altText='Contact-us' />
              </Box>
            </Fade>
          </Grid>
        </Grid>
      </SectionContainerLayoutWithFilter>
    </Layout>
  );
};

export default NewsletterPage;
